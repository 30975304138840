@keyframes fadein {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.delayed {
  nb-popover {
    animation: 1200ms fadein;
  }
}

.error {
  nb-popover {
    background-color: var(--checkbox-danger-focus-background-color);
    color: var(--checkbox-danger-active-checked-background-color);

    .arrow:after {
      border-bottom-color: var(--checkbox-danger-focus-background-color);
    }
  }
}
