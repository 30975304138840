@import '~@nebular/theme/styles/theming';
// Base
// https://akveo.github.io/nebular/docs/components/components-overview
@import './styles/base/functions';
@import './styles/base/colors';
@import './styles/base/base';

// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Poppins:600,700&display=swap');

// Themes
@import './styles/themes/pixy';
@import './styles/themes/pixy-dark';

// Install Themes
$nb-enable-css-custom-properties: true;
@import '~@nebular/theme/styles/globals';
@include nb-install() {
  @include nb-theme-global();
}
