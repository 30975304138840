$breakpoints: (
  xs: 0,
  is: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$container-max-widths: (
  xs: 380px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1700px,
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 0.75,
  ),
  4: $spacer,
  5: (
    $spacer * 1.5,
  ),
  6: (
    $spacer * 2,
  ),
  7: (
    $spacer * 3,
  ),
  8: (
    $spacer * 4,
  ),
);

$enable-negative-margins: true;
