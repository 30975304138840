@import '~@nebular/theme/styles/themes/default';

// Merge base vars with theme vars
$theme: map-merge(
  (
    layout-scrollbar-color: #50b0c2,
    layout-background-color: #ffffff,
    subheader-background-color: #f7f8fb,
  ),
  map-collect($primaryColorPalette, $base)
);

$theme-colors: (
  'primary': map-get($primaryColorPalette, 'color-primary-500'),
);
@import '../base/bootstrap.scss';
@import '~bootstrap/scss/bootstrap.scss';

$nb-themes: nb-register-theme($theme, pixy, default);
