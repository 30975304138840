@import '~@nebular/theme/styles/themes/dark';

$darkTheme: map-merge(
  map-collect($managerPrimaryColorPalette, $base),
  (
    layout-scrollbar-color: #9677b5,
    subheader-background-color: #3a3c3e,
  )
);

$theme-colors: (
  'primary': map-get($managerPrimaryColorPalette, 'color-primary-500'),
);
@import '../base/_bootstrap.scss';
@import 'bootstrap/scss/bootstrap.scss';

:root {
  @each $key, $spacer in $spacers {
    --pixy-gutter-#{$key}: #{$spacer};
  }
}

$nb-themes: nb-register-theme($darkTheme, pixy-dark, dark);
