$input: (
  input-basic-background-color: --background-basic-color-1,
  input-basic-hover-background-color: --background-basic-color-1,
  input-basic-hover-border-color: var(--input-basic-focus-border-color),
  input-basic-focus-background-color: var(--color-primary-transparent-100),
  input-primary-background-color: --background-basic-color-1,
  input-primary-hover-background-color: --background-basic-color-1,
  input-primary-hover-border-color: var(--input-basic-focus-border-color),
  input-primary-focus-background-color: var(--color-primary-transparent-100),
  input-danger-background-color: --background-basic-color-1,
  input-danger-hover-background-color: --background-basic-color-1,
  input-danger-hover-border-color: var(--input-basic-focus-border-color),
  input-danger-focus-background-color: var(--color-primary-transparent-100),
  input-medium-text-font-size: 1rem,
);

[nbInput].status-basic:read-only:hover,
[nbInput].status-basic:read-only {
  background-color: var(--input-basic-disabled-background-color);
  border-color: var(--input-basic-disabled-border-color);
  color: var(--input-basic-disabled-text-color);
}
