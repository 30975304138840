@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin';

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      header-background-color: ag-derived(background-color, $mix: foreground-color 8%),
      border-color: ag-derived(background-color, $mix: foreground-color 18%),
      odd-row-background-color: ag-derived(background-color, $mix: foreground-color 6%),
    )
  );
}

.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine .ag-root-wrapper-body {
  min-height: 500px;
}

.ag-checkbox-input-wrapper {
  margin-right: 5px;
}

.nb-theme-pixy-dark .ag-theme-alpine {
  @include ag-theme-alpine-dark(
    (
      header-background-color: ag-derived(background-color, $mix: foreground-color 8%),
      border-color: ag-derived(background-color, $mix: foreground-color 18%),
      odd-row-background-color: ag-derived(background-color, $mix: foreground-color 6%),
    )
  );
}

.ag-root-wrapper {
  border-radius: 5px;
  margin-bottom: 30px;
}

.cell-link {
  color: var(--color-primary-500);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.ag-cell:empty::after {
  color: #aaa;
  content: 'N/A' !important;
}

.page-title-and-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.active-link {
  border-bottom: solid 4px var(--color-primary-500) !important;
}

.internal {
  .scrollable-container {
    height: calc(100% - 80px) !important;
  }

  .layout {
    min-height: 100% !important;
  }
}
